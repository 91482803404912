<template>
    <div class="exporter">
        <div class="dark" @click="emit('close')"></div>
        <div class="export">
            <div class="title">Export Data</div>
            <div class="date">
                <div class="label">Set start and end datetime</div>
                <VueDatePicker v-model="date" range  minutes-increment="60"/>
            </div>
            <div class="btn" @click="export_data()">Export</div>
        </div>
    </div>
</template>

<script setup>
import moment from "moment";
import { defineProps, defineEmits, onMounted, ref } from "vue";

const props = defineProps(['dates', 'commodities_data'])
const emit = defineEmits(['close'])
const date = ref([])

onMounted(() => {
    const startDate = moment(props.dates[props.dates.length - 1]).format('YYYY-MM-DD HH:mm:ss') ;
    const endDate = moment(props.dates[0]).format('YYYY-MM-DD HH:mm:ss');
    date.value = [startDate, endDate];
})

const export_data = () => {

    let start_date = moment(date.value[0]).format('YYYY-MM-DD HH:mm:ss')
    let end_date = moment(date.value[1]).format('YYYY-MM-DD HH:mm:ss')

    let start_index = -1
    let end_index = -1

    for (let i = 0; i < props.dates.length; i++) {
        const element = props.dates[i];

        if (element == start_date) {
            end_index = i
        }
        if (element == end_date) {
            start_index = i
        }
    }


    if (start_index == -1 || end_index == -1) {
        alert('Invalid Date Range')
        return
    }

    let to_use_dates = props.dates.slice(start_index, end_index + 1)

    let csv_head = {
        'Commodity': 'commodity'
    }

    for (let i = 0; i < to_use_dates.length; i++) {
        const date = to_use_dates[i];
        
        csv_head[date] = date
    }


    let commodities = []

    for (let i = 0; i < props.commodities_data.length; i++) {
        const commodity = props.commodities_data[i];
        
        let price_array = commodity.prices.slice(start_index, end_index + 1)
        let commodity_prices = {
            'commodity': commodity.commodity
        }

        for (let j = 0; j < price_array.length; j++) {
            const price = price_array[j];
            commodity_prices[to_use_dates[j]] = price
        }

        commodities.push(commodity_prices)
    }


    exportCSVFile(csv_head, commodities, 'commodities_data')
}

function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
</script>

<style scoped>
.exporter {
    position: fixed;
    z-index: 1000;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;
}
.dark {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(3px);
}

.export {
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    box-sizing: border-box;

    position: relative
}

.date {
    margin: 20px 0px;
}
.label {
    font-size: 14px;
}
.btn {
    background-color: var(--bg-color);
    color: white;
    border-radius: 5px;
    padding: 8px 20px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}
</style>