<template>
    <div class="table">
        
        <div class="title_2">Additional Commodities</div>
        <div class="table_head" @click="toggle_star()">
            <div class="table_head_item" :class="[star ? 'starred' : '']">
                <starred_vue :size="'20px'" :style="{'height': '20px'}" v-if="star"/>
                <star_vue :size="'20px'" :style="{'height': '20px'}" v-else/>
            </div>
            <div class="table_head_item">#</div>
            <div class="table_head_item">
                Commodity
            </div>
            <div class="table_head_item">
                Monthly Avg
                <sort_vue :f="current_sort" :u="'price'"/>
            </div>
            <div class="table_head_item web">
                1 Month
                <sort_vue :f="current_sort" :u="'day_value'"/>
            </div>
            <div class="table_head_item web">
                12 Month
                <sort_vue :f="current_sort" :u="'percent'"/>
            </div>
            <div class="table_head_item">
                YOY
                <sort_vue :f="current_sort" :u="'weekly'"/>
            </div>
        </div>
        <div class="" v-for="(item, i) in commodities" :key="item.name">
            <commodity_vue :commodity="item" :i="i"/>
        </div>
        <div class="btn" v-if="!all" @click="show_all()">Show More</div>
    </div>
</template>

<script setup>

import Localbase from 'localbase'
let db = new Localbase('commodity')

import star_vue from '@/icons/star.vue'
import starred_vue from '@/icons/starred.vue';

import commodity_vue from './commodity.vue'

import { defineProps, ref, onMounted, watch } from 'vue';

import { store } from '@/store/store';


import { useToast } from 'vue-toastification';
const toast = useToast()

const props = defineProps(['commodities_data'])

const all_commodities = ref([])
const commodities = ref([])

const all = ref(false)

onMounted(() => {
    all_commodities.value = [...props.commodities_data]
    commodities.value = [...props.commodities_data]
    commodities.value.splice(20, props.commodities_data.length)
})

const show_all = () => {
    commodities.value = JSON.parse(JSON.stringify(all_commodities.value))
    all.value = true
}

const star = ref(false)


const toggle_star = () => {
    
    if (star.value) {
        commodities.value = JSON.parse(JSON.stringify(all_commodities.value))
        
        star.value = false
    } else {
        db.collection('watchlist2').get().then(docs => {
            if (docs.length == 0 ) {
                toast.info("There are no commoditiies on your watch list")
            } else {

                let watchlist = []
                for (let x of docs) {
                    for (let y of all_commodities.value) {
                        if (x.name === y.name) {
                            console.log(y)
                            watchlist.push(y)
                        }
                    }
                }



                commodities.value = JSON.parse(JSON.stringify(watchlist))

                star.value = true
            }
        })
    }
}

watch(() => store.search_text, () => {
    
    let search_text = store.search_text
    
    let new_c = []

    star.value = false
    all.value = true

    if (search_text == '') {
        commodities.value = JSON.parse(JSON.stringify(all_commodities.value))
        commodities.value.splice(20, props.commodities_data.length)
        all.value = false
        return
    }

    for(let x of all_commodities.value) {
        if (x.name.toLowerCase().includes(search_text.toLowerCase())) {
            new_c.push(x)
        }
    }

    commodities.value = JSON.parse(JSON.stringify(new_c))
})

</script>

<style scoped>

    .title_2 {
        font-weight: 500;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .table {
        position: relative;
        border-radius: 15px;
        /* overflow: hidden; */
        /* border: 1px solid #e0e0e0; */
        min-width: 760px;
        /* position: relative; */

    }
    .table_head {
        display: grid;
        grid-template-columns: 5% 5% 40% 20% 10% 10% 10% ;
        /* background-color: var(--transparent-color); */
        /* color: var(--bg-color); */
        font-weight: 500;
        font-size: 12px;
        padding: 20px;
        border-bottom: 1px solid #333;

        background-color: white;

        position: sticky;
        top: 0px;
        z-index: 2;
    }
    .table_head_item {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
    }

    
    
    .starred {
        fill: rgb(221, 148, 0);
    }


    .btn {
        padding: 20px 40px;
        color: var(--bg-color);
        cursor: pointer;
        text-align: center;
        font-size: 14px;
    }


    @media screen and (max-width: 800px) {
        .web {
            display: none;
        }

        .table {
            min-width: unset;
        }
        
        .table_head {
            display: grid;
            grid-template-columns: 8% 7% 45% 20% 15% ;
        }
        .table_data {
            display: grid;
            grid-template-columns: 8% 7% 45% 20% 15% ;

        }
    }
</style>