<template>
    <div class="sort">
        <div class="icon" :class="[props.f.by == u && props.f.order == 'desc' ? 'p' : '']">
            <arrow_up :size="'20px'" :style="{'height': '8px'}"/>
        </div>
        <div class="icon" :class="[props.f.by == u && props.f.order == 'asc' ? 'n' : '']">
            <arrow_down :size="'20px'" :style="{'height': '8px'}"/>
        </div>
    </div>
</template>

<script setup>
import arrow_up from '../icons/arrow_up.vue';
import arrow_down from '../icons/arrow_down.vue';

import { defineProps } from 'vue'

const props = defineProps(['f', 'u'])

</script>

<style scoped>
    .sort {
        margin-top: -10px;
        fill: #333;
    }

    .p {
        fill: green;
    }
    .n {
        fill: tomato;
    }
</style>