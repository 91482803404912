<template>
    <div class="blog">
        <div class="image" v-if="props.blog.image != ''">
            <img :src="'https://php.commodities-tracker.com/images/blog/' + props.blog.image"/>
        </div>
        <div class="content">
            <div class="title" @click="open_blog()">{{ props.blog.title }}</div>
            <div class="text">
                {{ blog_text }}
            </div>
            <div class="date">
                <date_icon :size="'20px'" :style="{'height': '20px'}"/>
                <div>{{ date }}</div>
            </div>
            <div class="btn" @click="open_blog()">Continue Reading</div>
        </div>
    </div>
</template>

<script setup>
import moment from 'moment';

import date_icon from '@/icons/date.vue';

import { defineProps, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';


const props = defineProps(['blog'])
const router = useRouter()

const blog_text = ref('')
const date = ref('')

onMounted(() => {
    blog_text.value = props.blog.content.replace(/<[^>]*>/g, '')

    date.value = moment(props.blog.created).format('YYYY-MM-DD')
})

const open_blog = () => {
    localStorage.setItem('blog', JSON.stringify(props.blog))

    router.push('/blog/' + props.blog.link)
}

</script>

<style scoped>
.blog {
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
}
.image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    overflow: hidden;
}
.image img {
    width: 100%;
}
.content {
    padding: 20px;
}
.title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
    cursor: pointer;
}
.text {
    
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

    font-size: 14px;
    opacity: .6;
}
.date {
    display: flex;
    align-items: center;
    gap: 10px;
    opacity: .6;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
}

.btn {
    /* background-color: rgba(41, 6, 69, 0.3); */
    color: #290645;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}
</style>