import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)
app.use(router)


import axios from 'axios'
import VueAxios from 'vue-axios'

const axios_instance = axios.create({
    // baseURL: 'http://localhost:5000'
    baseURL: 'https://python.commodities-tracker.com'
});

const axios_2 = axios.create({
    baseURL: 'https://php.commodities-tracker.com/index.php'
    // baseURL: 'http://localhost/commodities/index.php'
})

app.use(VueAxios, { 'axios': axios_instance, 'axios_2': axios_2 } )
app.provide('axios', app.config.globalProperties.axios)
app.provide('axios_2', app.config.globalProperties.axios_2)



import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
    // You can set your default options here
};
app.use(Toast, options);


import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

app.component('VueDatePicker', VueDatePicker);



app.mount('#app')
