<template>
    <div class="footer">
        © 2024 Commodities Tracker
    </div>
</template>

<script setup>

</script>

<style scoped>
.footer {
    padding: 20px;
    background-color: #290645;
    color: white;
    font-size: 14px;
    text-align: center;
}
</style>