<template>
    <div class="commodities">
        <div class="head">
            <div class="title">Commodities</div>
            <div class="btn" @click="show_export = !show_export">Export</div>
        </div>
        
        <div class="load" v-if="loading">
            <loader/>
        </div>
        <div v-else>
            <div class="table">
                <div class="commodities_section">
                    <div class="commodity fixed">Commodity</div>
                    <div class="commodity" v-for="commodity in commodities" :key="commodity.symbol">
                        {{ commodity.commodity }}
                    </div>
                </div>
                <div>
                    <div class="table_head">
                        <div class="head_item" v-for="(date, i) in dates" :key="i">{{moment(date).format('YYYY-MM-DD HH:mm')}}</div>
                    </div>
                    <div class="prices">
                        <div class="prices_flex" v-for="commodity_data in commodities_data" :key="commodity_data.commodity">
                            <div class="price" v-for="(price, i) in commodity_data.prices" :key="i">{{ price }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <exporter :dates="dates" :commodities_data="commodities_data" v-if="show_export" @close="show_export = false"/>
</template>

<script setup>
import exporter from '@/components/admin/commodities/exporter.vue'

import moment from 'moment'
import loader from "@/components/loader.vue";
import { inject, ref } from "vue"


// const page_views = ref([])
const loading = ref(true)
const dates = ref([])
const commodities = ref([])
const commodities_data = ref([])

const show_export = ref(false)

const axios_2 = inject('axios_2')
const init = () => {
    axios_2.post('/admin/commodities')
    .then(data => {
        let r = data.data
        if (r.success) {
            console.log(r);

            dates.value = r.dates
            commodities.value = r.commodities
            commodities_data.value = r.commodities_data

            loading.value = false
        }
    })
}
init()

</script>

<style scoped>
.commodities {
    padding: 20px;
    position: relative;
}
.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title {
    
    font-size: 18px;
    font-weight: 600;
}
.btn {
    background-color: var(--bg-color);
    color: white;
    border-radius: 5px;
    padding: 5px 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.load {
    margin-top: 50px;
}


.table {
    position: relative;
    margin-top: 20px;

    display: flex;
    overflow: auto;

    width: 100%;
    height: calc(100vh - 170px);
}
.commodities_section {
    position: sticky;
    z-index: 2;
    left: 0px;
    background-color: white;
}
.commodity {
    font-size: 12px;
    padding: 5px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background-color: white;
    word-break: keep-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 150px;
}
.fixed {
    position: sticky;
    z-index: 3;
    top: 0px;
    left: 0px;
}
.table_head {
    height: fit-content;
    position: sticky;
    top: 0px;
    z-index: 1;
    display: flex;
    /* grid-auto-columns: 50px; */
    /* gap: 20px; */
    font-size: 12px;
    /* margin-left: 97px; */
}
.head_item {
    word-break: keep-all;
    white-space: nowrap;
    padding: 5px;
    width: 120px;
    box-sizing: border-box;
    background-color: white;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.prices_flex {
    display: flex;
}
.price {
    
    font-size: 12px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    width: 120px;
    box-sizing: border-box;
}

@media screen and (max-width: 700px) {
    .table {
        height: calc(100vh - 210px);

    }
}
</style>