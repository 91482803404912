import { reactive } from "vue";
import { io } from "socket.io-client";

export const state = reactive({
    connected: false
});

// "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.NODE_ENV === "production" ? "https://python.commodities-tracker.com" : "http://localhost:3000";
const URL = 'https://realtime.commodities-tracker.com/'

export const socket = io(URL);

socket.on("connect", () => {
    state.connected = true;
});

socket.on("disconnect", () => {
    state.connected = false;
});
