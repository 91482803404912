<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960" >
            <path d="M108.85-414.39 60-449.46 245-746l117.69 137.69 156.16-254.23 117.69 176.93L771.15-900l48.08 35.46-181.46 
            289-116.69-175.92L371-507.16l-118.31-138.3-143.84 231.07ZM572.31-240q45.07 0 76.19-31.12 31.12-31.12 31.12-76.19 
            0-45.08-31.13-76.19-31.12-31.12-76.19-31.12-45.07 0-76.19 31.12Q465-392.37 465-347.3q0 45.07 31.12 76.18Q527.24-240 
            572.31-240Zm205.15 140L667.15-210.31q-20.23 14.77-44.34 22.54-24.12 7.77-50.5 7.77-69.71 0-118.51-48.78Q405-277.56 
            405-347.24q0-69.68 48.78-118.53 48.78-48.84 118.46-48.84 69.68 0 118.53 48.79 48.84 48.8 48.84 118.51 0 26.39-7.77 
            50.69-7.76 24.31-22.53 44.54l109.92 110.31L777.46-100Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>