<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M480-100q-70.77 0-132.61-26.77-61.85-26.77-107.85-72.77-46-46-72.77-107.85Q140-369.23 140-440q0-70.77 
            26.77-132.61 26.77-61.85 72.77-107.85 46-46 107.85-72.77Q409.23-780 480-780q70.77 0 132.61 26.77 61.85 26.77 
            107.85 72.77 46 46 72.77 107.85Q820-510.77 820-440q0 70.77-26.77 132.61-26.77 61.85-72.77 107.85-46 46-107.85 
            72.77Q550.77-100 480-100Zm0-340Zm118.92 161.08 42.16-42.16L510-452.15V-640h-60v212.15l148.92 
            148.93ZM230.15-844.46l42.16 42.15-154.62 154.62-42.15-42.16 154.61-154.61Zm499.7 0 154.61 154.61-42.15 
            42.16-154.62-154.62 42.16-42.15ZM480-160q116.62 0 198.31-81.69T760-440q0-116.62-81.69-198.31T480-720q-116.62 
            0-198.31 81.69T200-440q0 116.62 81.69 198.31T480-160Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>