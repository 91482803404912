<template>
    <div>
        <div class="filter" @click="open_pop()">
            <filter_vue :size="'20px'" :style="{'height': '20px'}"/>
            <div class="filter_no" v-if="filter_no">{{ filter_no }}</div>
        </div>

        <div class="pp" v-if="is_pop">
            <div class="overlay" @click="is_pop = false"></div>
            <div class="filter_box">
                <div class="title">Filter By</div>

                <div class="filter_wrapper">
                    <div class="label">Category</div>
                    <div class="flex">
                        <div class="flex-2" v-for="(category, i) in ca" :key="i">
                            <div class="box" :class="[category.a ? 'a' : '']" @click="choose_category(i)">
                                <check_vue :size="'15px'" :style="{'height': '20px'}" v-if="category.a"/>
                            </div>
                            <div class="flex_text">{{ category.t }}</div>
                        </div>
                    </div>

                    <div class="label">Currencies</div>
                    <div class="flex">
                        <div class="flex-2" v-for="(currency, i) in cu" :key="i">
                            <div class="box" :class="[currency.a ? 'a' : '']" @click="choose_currency(i)">
                                <check_vue :size="'15px'" :style="{'height': '20px'}" v-if="currency.a"/>
                            </div>
                            <div class="flex_text">{{ currency.t }}</div>
                        </div>
                    </div>
                    <div class="go" @click="apply()">Apply</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import filter_vue from '@/icons/filter.vue';
import check_vue from '@/icons/check.vue';

import { ref, defineProps, defineEmits } from 'vue';

const props = defineProps(['categories', 'currencies'])

const is_pop = ref(false)

const ca = ref([])
const cu = ref([])

const open_pop = () => {
    if (ca.value.length > 0) {
        is_pop.value = true
        return
    }

    for (let x of props.categories) {
        ca.value.push({
            'a': false,
            't': x
        })
    }
    for (let x of props.currencies) {
        cu.value.push({
            'a': false,
            't': x
        })
    }

    is_pop.value = true
}

const choosen_category = ref([])
const choosen_currency = ref([])

const choose_category = (i) => {

    let index = choosen_category.value.indexOf(ca.value[i].t)
    if (index == -1) {
        ca.value[i].a = true
        choosen_category.value.push(ca.value[i].t)
    } else {
        ca.value[i].a = false
        choosen_category.value.splice(index, 1)
    }
}

const choose_currency = (i) => {
    
    let index = choosen_currency.value.indexOf(cu.value[i].t)
    if (index == -1) {
        cu.value[i].a = true
        choosen_currency.value.push(cu.value[i].t)
    } else {
        cu.value[i].a = false
        choosen_currency.value.splice(index, 1)
    }
}

const filter_no = ref(0)

const emit = defineEmits(['filter_by'])
const apply = () => {
    is_pop.value = false
    filter_no.value = choosen_category.value.length + choosen_currency.value.length

    emit('filter_by', {
        'category': [...choosen_category.value],
        'currency': [...choosen_currency.value]
    })
}

</script>

<style scoped>

    .filter {
        height: 40px;
        aspect-ratio: 1/1;
        background-color: white;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
    }
    .filter_no {
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: tomato;
        color: white;
        font-size: 12px;
        font-weight: 500;

        width: 20px;
        height: 20px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .pp {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        padding: 20px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .overlay {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(1px);
    }
    .filter_box {
        position: relative;
        border-radius: 5px;
        background-color: white;
        color: black;

        max-width: 350px;
        width: 100%;
        /* padding: 20px; */
        
        /* box-sizing: border-box; */
        border-radius: 5px;

        font-size: 16px;
        font-weight: 400;

        animation: pop .2s linear;
    }
    @keyframes pop {
        from {
            transform: scale(.9);
        }
        to {
            transform: scale(1);
        }
    }

    .title {
        font-weight: 700;
        padding: 15px 20px;
        border-bottom: 1px solid #ddd;
    }
    .filter_wrapper {
        padding: 20px;
    }
    .label {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .flex {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 20px;
    }
    .flex-2 {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .box {
        width: 18px;
        height: 18px;
        border-radius: 2px;
        border: 1px solid #ddd;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .flex_text {
        font-size: 12px;
        color: gray;
    }

    .go {
        padding: 10px;
        border-radius: 5px;
        background-color: var(--bg-color);
        color: white;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        text-align: center;
    }

    
    @media screen and (max-width: 700px) {
        .filter {
            background-color: unset;
            height: fit-content;
            fill: #2b70fa;
            padding: 5px;
        }
        .filter_no {
            left: -7px;

            width: 18px;
            height: 18px;
        }
    }
</style>