<template>
    <div class="web">
        <div class="table_data">
            <div class="star" @click="toggle_star()" :class="[star ? 'starred' : '']">
                <starred_vue :size="'20px'" :style="{'height': '20px'}" v-if="star"/>
                <star_vue :size="'20px'" :style="{'height': '20px'}" v-else/>
            </div>
            <div class="table_data_item">
                <div class="count">{{ commodity.no }}</div>
            </div>
            <div class="table_data_item">
                <a :href="commodity.link" target="blank">
                    <div class="name">
                        {{commodity.commodity}}

                        <div class="icon">
                            <link_vue :size="'15px'" :style="{'height': '15px'}"/>
                        </div>
                    </div>
                    <div class="pair">
                        {{ commodity.pair }}
                    </div>
                </a>
            </div>
            <div class="table_data_item">
                <div class="price">{{commodity.price}}</div>
            </div>
            <div class="table_data_item" :class="[commodity.day.direction]">
                <div class="day">
                    <div class="icon">
                        <arrow :size="'20px'" :style="{'height': '22px'}"/>
                    </div>
                    {{commodity.day.value}}
                </div>
            </div>
            <div class="table_data_item">{{commodity.percent}}</div>
            <div class="table_data_item">{{commodity.weekly}}</div>
            <div class="table_data_item">{{commodity.monthly}}</div>
            <div class="table_data_item">{{commodity.yoy}}</div>
            <div class="table_data_item">
                <div class="energy">
                    {{commodity.category}}
                </div>
            </div>
        </div>
    </div>


    
    <div class="mobile">
        <div class="grid">
            <div class="star" @click="toggle_star()" :class="[star ? 'starred' : '']">
                <starred_vue :size="'20px'" :style="{'height': '20px'}" v-if="star"/>
                <star_vue :size="'20px'" :style="{'height': '20px'}" v-else/>
            </div>
            <div>
                <div class="count">
                    {{ commodity.no }}
                </div>
            </div>
            <div>
                
                <a :href="commodity.link" target="blank">
                    <div class="name">
                        {{commodity.commodity}}

                        <div class="icon">
                            <link_vue :size="'15px'" :style="{'height': '15px'}"/>
                        </div>
                    </div>
                    <div class="pair">
                        {{ commodity.pair }}
                    </div>
                </a>
            </div>
            <div>
                <div class="price">{{commodity.price}}</div>
            </div>
            <div :class="[commodity.day.direction]">
                <div class="day">
                    <div class="icon">
                        <arrow :size="'20px'" :style="{'height': '22px'}"/>
                    </div>
                    {{commodity.day.value}}
                </div>
            </div>
            <div class="expand">
                <div class="expand_icon" :class="[opened ? 'rotate_icon' : '']" @click="opened = !opened">
                    <expand :size="'25px'" :style="{'height': '25px'}"/>
                </div>
            </div>
        </div>
        <div class="flex" v-if="opened">
            <div>
                <div class="label">%</div>
                <div class="value">
                    {{ commodity.percent }}
                </div>
            </div>
            <div>
                <div class="label">Weekly</div>
                <div class="value">
                    {{ commodity.weekly }}
                </div>
            </div>
            <div>
                <div class="label">Monthly</div>
                <div class="value">
                    {{ commodity.monthly }}
                </div>
            </div>
            <div>
                <div class="label">YOY</div>
                <div class="value">
                    {{ commodity.yoy }}
                </div>
            </div>
            <div>
                <div class="label">Category</div>
                <div class="value">
                    {{ commodity.category }}
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
import Localbase from 'localbase'
let db = new Localbase('commodity')

import star_vue from '@/icons/star.vue'
import starred_vue from '@/icons/starred.vue';

import arrow from "@/icons/arrow.vue";
import link_vue from "@/icons/link.vue";
import expand from "@/icons/expand.vue";

import { ref, defineProps, watch } from 'vue';

import { store } from '@/store/store.js'

const props = defineProps(['commodity'])

const star = ref(false)
const commodity = ref(null)

const init = () => {
    commodity.value = props.commodity

    db.collection('watchlist').doc({'symbol': commodity.value.symbol}).get().then(doc => {
        if (doc) {
            star.value = true
        }
    })
}
init()


const toggle_star = () => {
    if (star.value) {
        db.collection('watchlist').doc({'symbol' : commodity.value.symbol}).delete().then(() => {
            star.value = false
        })
    } else {
        db.collection('watchlist').add({
            'symbol': commodity.value.symbol
        }).then(() => {
            star.value = true
        })
    }
}

const opened = ref(false)

watch(() => store.commodities[commodity.value.symbol], (new_data) => {
    commodity.value = new_data
})

</script>

<style scoped>
    
    .table_data {
        display: grid;
        grid-template-columns: 5% 5% 20% 10% 10% 10% 10% 10% 10% 10%;
        align-items: center;
        /* font-weight: 500; */
        font-size: 14px;
        border-bottom: 1px solid #e0e0e0;
        padding: 10px 20px;
    }
    .table_data:nth-child(odd) {
        /* background-color: #e0e0e0; */
    }
    .star {
        cursor: pointer;
    }
    .starred {
        fill: rgb(221, 148, 0);
    }
    .table_data_item {
    }

    .day {
        display: flex;
        align-items: center;
        gap: 2px;
    }

    .count {
        color: gray;
        font-size: 12px;
    }

    .name {
        font-weight: 600;

        display: flex;
        align-items: center;
        gap: 2px;
    }
    .pair {
        font-size: 12px;
        color: gray;
        margin-top: 2px;
    }

    .price {
        font-weight: 500;
    }

    .negative {
        color: tomato;
        fill: tomato;
    }
    .negative .icon {
        transform: rotate(90deg);
    }

    .energy {
        background-color: #e0e0e0;
        font-weight: 600;
        font-size: 12px;
        padding: 7px 15px;
        border-radius: 2px;
        width: fit-content;
    }


    .positive {
        color: green;
        fill: green;
    }
    .positive .icon {
        transform: rotate(-90deg);
    }





    .mobile {
        border-bottom: 1px solid #e0e0e0;

    }
    .grid {
        display: grid;
        grid-template-columns: 8% 7% 35% 20% calc(30% - 30px) 30px ;
        align-items: center;
        font-size: 14px;
        padding: 10px 20px;
    }

    .day {
        display: flex;
        align-items: center;
        gap: 2px;
    }

    .count {
        color: gray;
        font-size: 12px;
    }
    .name {
        font-weight: 600;

        display: flex;
        align-items: center;
        gap: 2px;
    }
    .pair {
        font-size: 12px;
        color: gray;
        margin-top: 2px;
    }

    .price {
        font-weight: 500;
    }

    .negative {
        color: tomato;
        fill: tomato;
    }
    .negative .icon {
        transform: rotate(90deg);
    }

    .positive {
        color: green;
        fill: green;
    }
    .positive .icon {
        transform: rotate(-90deg);
    }


    .expand {
        margin-left: auto;
    }
    .expand_icon {
        transform: rotate(-90deg);
        fill: #333;
        transition: .2s;
    }
    .rotate_icon {
        transform: rotate(0deg);
    }


    .flex {

        font-size: 14px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        padding: 20px;
        padding-top: 10px;
    }
    .label {
        font-size: 12px;
        color: gray;
        margin-bottom: 2px;
    }
    .value {
        font-size: 12px;
        font-weight: 500;
    }


    
    .mobile {
        display: none;
    }

    
    @media screen and (max-width: 800px) {
        .web {
            display: none;
        }
        .mobile {
            display: block;
        }
    }
</style>