<template>
    <div class="page">
        <div class="form">
            <div class="title">Admin Login</div>
            <div class="input">
                <input type="text" placeholder="Username" v-model="username">
            </div>
            <div class="input">
                <input type="password" placeholder="Password" v-model="password">
            </div>
            <loading_vue :height="'44px'" v-if="loading"/>
            <div class="btn" v-else @click="login()">Login</div>
        </div>
    </div>
</template>

<script setup>
import Localbase from 'localbase'
let db = new Localbase('commodities')

import loading_vue from '@/components/loading.vue';


import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import { inject, ref } from 'vue';

const router = useRouter()

const init = () => {
    
    db.collection('auth').doc({ user: 'admin' }).get().then(document => {
        if (document == undefined) {
            console.log('login');
        } else {
            router.push('/admin')
        }
    })
}
init()

const loading = ref(false)

const username = ref('')
const password = ref('')

const axios_2 = inject('axios_2')

const toast = useToast()
const login = () => {
    if (username.value == '' || password.value == '') {
        toast.error('Enter username and pasword')
        return
    }

    loading.value = true

    let form = new FormData()
    form.append('username', username.value)
    form.append('password', password.value)

    axios_2.post('/admin/login', form)
    .then(data => {
        let r = data.data
        if (r.success) {
            toast.success('Login success')
            

            db.collection('auth').add({
                'user': 'admin',
                'key': r.session
            }).then( () => {
                router.push('/admin')
                loading.value = false
            })
        } else {
            if (r.username) {
                toast.error('Incorrect username')
            }
            if (r.password) {
                toast.error('Incorrect password')
            }
        }
        loading.value = false
    })
}
</script>

<style scoped>

.page {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
}

.form {
    background-color: white;
    max-width: 300px;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
}
.title {
    font-weight: 500;
    margin-bottom: 20px;
}
.input {
    margin-bottom: 20px;
}
input {
    padding: 12px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
}
.btn {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--bg-color);
    color: white;
}
</style>