<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" :width="size" :height="size">
            <path d="M382-253.85 168.62-467.23 211.38-510 382-339.38 748.62-706l42.76 42.77L382-253.85Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>