<template>
    <div class="blog_page">
        <header_vue/>
        <banner_vue/>

        <div class="loading" v-if="loading">
            <loader_vue/>
        </div>
        <div class="main">
            <blog_vue v-for="blog in blogs" :key="blog.link" :blog="blog"/>
        </div>

        <footer_vue/>
    </div>
</template>

<script setup>
import header_vue from '@/components/header.vue';
import banner_vue from '@/components/blog/banner.vue';

import loader_vue from '@/components/loader.vue';
import blog_vue from '@/components/blog/blog.vue';

import footer_vue from '@/components/footer.vue';
import { onMounted, inject, ref } from 'vue';

const axios = inject('axios_2')

const blogs = ref([])
const loading = ref(true)

onMounted(() => {
    axios.post('/blog_posts')
    .then(data => {
        let r = data.data
        blogs.value = r.blog
        loading.value = false
    })
})

</script>

<style scoped>
.blog_page {
    background-color: #f1f2f3;
}
.loading {
    padding: 50px 0px;
}
.main {
    padding: 50px 20px;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    gap: 30px;

    max-width: 1100px;
    margin: 0 auto;

}
</style>