<template>
    <div class="table_data">
        
        <div class="star" @click="toggle_commodity_star(commodity.name)" :class="[star ? 'starred' : '']">
            <starred_vue :size="'20px'" :style="{'height': '20px'}" v-if="star"/>
            <star_vue :size="'20px'" :style="{'height': '20px'}" v-else/>
        </div>
        <div class="table_data_item">
            <div class="count">{{ props.i + 1 }}</div>
        </div>
        <div class="table_data_item">
            <a :href="commodity.link" target="blank">
                <div class="name">
                    {{commodity.name}}

                    <div class="icon">
                        <link_vue :size="'15px'" :style="{'height': '15px'}"/>
                    </div>
                </div>
            </a>
        </div>
        <div class="table_data_item">
            <div class="price">{{commodity.avg_price}}</div>
        </div>
        <div class="table_data_item web">{{commodity.month}}</div>
        <div class="table_data_item web">{{commodity.year}}</div>
        <div class="table_data_item">{{commodity.yoy}}</div>
    </div>
</template>

<script setup>
import Localbase from 'localbase'
let db = new Localbase('commodity')

import star_vue from '@/icons/star.vue'
import starred_vue from '@/icons/starred.vue';

import link_vue from "@/icons/link.vue";
import { ref, defineProps } from 'vue';

const props = defineProps(['commodity', 'i'])

const star = ref(false)
const commodity = ref({})

const init = () => {
    commodity.value = props.commodity

    db.collection('watchlist2').doc({'name': commodity.value.name}).get().then(doc => {
        if (doc) {
            star.value = true
        }
    })
}
init()


const toggle_commodity_star = (name) => {
    if (star.value) {
        db.collection('watchlist2').doc({'name' : name}).delete().then(() => {
            star.value = false
        })
    } else {
        db.collection('watchlist2').add({
            'name': name
        }).then(() => {
            star.value = true
        })
    }
}

</script>

<style scoped>

    
    .table_data {
        display: grid;
        grid-template-columns: 5% 5% 40% 20% 10% 10% 10%;
        align-items: center;
        /* font-weight: 500; */
        font-size: 14px;
        border-bottom: 1px solid #e0e0e0;
        padding: 10px 20px;
    }
    
    
    .starred {
        fill: rgb(221, 148, 0);
    }

    
    .day {
        display: flex;
        align-items: center;
        gap: 2px;
    }

    .count {
        color: gray;
        font-size: 12px;
    }

    .name {
        font-weight: 600;

        display: flex;
        align-items: center;
        gap: 2px;
    }
    .pair {
        font-size: 12px;
        color: gray;
        margin-top: 2px;
    }

    .price {
        font-weight: 500;
    }
    

    .btn {
        padding: 20px 40px;
        color: var(--bg-color);
        cursor: pointer;
        text-align: center;
        font-size: 14px;
    }


    @media screen and (max-width: 800px) {
        .web {
            display: none;
        }

        .table {
            min-width: unset;
        }
        
        .table_head {
            display: grid;
            grid-template-columns: 8% 7% 45% 20% 15% ;
        }
        .table_data {
            display: grid;
            grid-template-columns: 8% 7% 45% 20% 15% ;

        }
    }
</style>