<template>
    <div class="blog">
        <div class="image" v-if="props.blog.image != ''">
            <img :src="'https://php.commodities-tracker.com/images/blog/' + props.blog.image"/>
        </div>
        <div class="content">
            <div class="title" @click="open_blog()">{{ props.blog.title }}</div>
            <div class="text">
                {{ blog_text }}
            </div>
            <div class="date">
                <date_icon :size="'20px'" :style="{'height': '20px'}"/>
                <div>{{ date }}</div>
            </div>
            <div class="options">
                <div class="option" @click="show_edit = true">Edit</div>
                <div class="option del" @click="show_delete = !show_delete">Delete</div>
            </div>
            <div v-if="show_delete">
                <div v-if="delete_loading">
                    <loader_vue/>
                </div>
                <div class="delete" v-else>
                    <div class="delete_text">Do you want to proceed?</div>
                    <div class="delete_option">
                        <div class="click del" @click="del()">Yes</div>
                        <div class="click" @click="show_delete = false">No</div>
                    </div>
                </div>
            </div>
        </div>

        <edit_vue v-if="show_edit" @close="show_edit = false" :blog_edit="props.blog"/>
    </div>
</template>

<script setup>
import moment from 'moment';

import edit_vue from './edit.vue';
import date_icon from '@/icons/date.vue';

import loader_vue from '@/components/loader.vue';

import { defineProps, onMounted, inject, ref } from 'vue';
import { useRouter } from 'vue-router';

const axios = inject('axios_2')

const props = defineProps(['blog'])
const router = useRouter()

const show_edit = ref(false)
const show_delete = ref(false)

const blog_text = ref('')
const date = ref('')


onMounted(() => {
    blog_text.value = props.blog.content.replace(/<[^>]*>/g, '')

    date.value = moment(props.blog.created).format('YYYY-MM-DD')
})

const open_blog = () => {
    localStorage.setItem('blog', JSON.stringify(props.blog))

    router.push('/blog/' + props.blog.link)
}

const delete_loading = ref(false)
const del = () => {

    delete_loading.value = true

    let form = new FormData()
    form.append('link', props.blog.link)

    axios.post('/admin/blog/delete', form)
    .then(data => {
        let r = data.data
        if (r.success) {
            router.go()
        }
    })
}

</script>

<style scoped>
.blog {
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid #dddddd;
}
.image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    overflow: hidden;
}
.image img {
    width: 100%;
}
.content {
    padding: 20px;
    padding-bottom: 10px;
}
.title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
    cursor: pointer;
}
.text {
    
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

    font-size: 14px;
    opacity: .6;
}
.date {
    display: flex;
    align-items: center;
    gap: 10px;
    opacity: .6;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
}

.btn {
    /* background-color: rgba(41, 6, 69, 0.3); */
    color: #290645;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

.options {
    display: flex;
    justify-content: space-around;
    gap: 20px;
}
.option {
    font-size: 14px;
    cursor: pointer;
    padding: 10px;
}
.del {
    color: tomato;
}

.delete {
    /* background-color: tomato; */
}
.delete_text {
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
}
.delete_option {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    font-size: 14px;
}
.delete_option .del {
    background-color: tomato;
    color: white;
    padding: 5px 20px;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer
}
.click {
    cursor: pointer;
}
</style>