<template>
    <div class="banner_wrapper">
        <div class="banner">
            <div class="content">
                <div class="headline">
                    Stay Ahead with Real-Time Commodity Prices
                </div>
                <div class="sub">
                    Your trusted source for accurate and timely price tracking on essential commodities. 
                    From agriculture to fuel and metals, stay updated to make smarter decisions.
                </div>
                <div class="buttons">
                    <div class="btn">Latest Prices</div>
                    
                    <a href="https://weeklycommoditiesreport.substack.com" target="blank">
                        <div class="btn blue">Subscribe</div>
                    </a>
                </div>
            </div>
            <div class="image">
                <img src="../assets/banner.png"/>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
.banner_wrapper {

    background-color: #290645;
    /* min-height: 100vh; */

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
}
.banner {
    
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;

}
.content {
    max-width: 600px;
    width: 100%;
}
.headline {
    font-size: 40px;
    line-height: 50px;
    font-weight: 800;
}
.sub {
    margin-top: 20px;
    font-size: 14px;
    line-height: 28px;
    opacity: .6;
}

.buttons {
    margin-top: 20px;
    display: flex;
    gap: 20px;
}
.btn {
    background-color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    color: #290645;
    font-size: 14px;
    font-weight: 500;
}
.blue {
    background-color: #2b70fa;
    color: white;
}

.image img {
    max-width: 500px;
}


@media screen and (max-width: 750px) {
    .headline {
        font-size: 30px;
        line-height: 40px;
    }
    .content {
        max-width: 100%;
    }
    .image img {
        max-width: 100%;
    }
}
</style>