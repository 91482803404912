<template>
    <div class="loader" :class="[white ? 'white' : '']" :style="{'width': width, 'height': height}">
        <div class="circle"></div>
    </div>
</template>

<script>
export default {
    props: ['width', 'height', 'white']
}
</script>

<style scoped>
    .loader {
        background-color: var(--bg-color);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
    }

    .circle {
        width: 20px;
        height: 20px;
        border: 3px solid transparent;
        border-top-color: white;
        border-left-color: white;
        animation: 1s rotate linear infinite;
        border-radius: 50%;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .white {
        background-color: white;
    }
    .white .circle {
        border-top-color: var(--main);
        border-left-color: var(--main);
    }
</style>