<template>
    <div class="admin" v-if="ready">
        <div class="header">
            <div class="name">C-T Admin</div>
            <div class="logout" @click="logout()">Logout</div>
        </div>
        <div class="flex_auto">
            <div class="menu">
                <router-link to="/admin">
                    <div class="menu_item" :class="[route.name == 'dashboard' ? 'active': '']">Dashboard</div>
                </router-link>
                <router-link to="/admin/commodities">
                    <div class="menu_item" :class="[route.name == 'commodities' ? 'active': '']">Commodities</div>
                </router-link>
                <router-link to="/admin/blog">
                    <div class="menu_item" :class="[route.name == 'admin_blog' ? 'active': '']">Blog</div>
                </router-link>
            </div>
            <div class="route">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script setup>
import Localbase from 'localbase'
let db = new Localbase('commodities')


import { inject, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const router = useRouter()
const route = useRoute()

const axios_2 = inject('axios_2')

const ready = ref(false)

const init = () => {
    
    db.collection('auth').doc({ user: 'admin' }).get().then(document => {
        if (document == undefined) {
            router.push('/admin/login')
            // this.$root.$data.login = false
        } else {
            axios_2.defaults.headers.common['Auth'] = document.key
            ready.value = true
        }

        ready.value = true
    })
}
init()

const logout = () => {
            
    db.collection('auth').doc({ user: 'admin' }).delete()
    router.push('/admin/login')
}

</script>

<style scoped>
.admin {
    height: 100vh;

    display: flex;
    flex-direction: column;
}
.header {
    background-color: var(--bg-color);
    color: white;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.name {
    font-weight: 600;
    font-size: 18px;
}
.logout {
    border: 2px solid #fff;
    padding: 7px 15px;
    border-radius: 10px;
    font-size: 12px;
    cursor: pointer;
}
.flex_auto {
    overflow: auto;
    flex: auto;

    display: grid;
    grid-template-columns: 250px auto;
}
.menu {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f8f8f8;
}
.menu_item {
    padding: 15px;
    border-radius: 5px;
    background-color: white;
    /* color: white; */
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
}
.active {
    background-color: var(--bg-color);
    color: white;

}
.route {
    height: 100%;
    flex: auto;
    overflow: auto;
}


@media screen and (max-width: 700px) {
    .menu {
        /* display: none; */
    }

    .flex_auto {
        display: flex;
        flex-direction: column-reverse;
    }
    .menu {
        display: grid;
        grid-template-columns: auto auto auto;
        padding: 10px;
        gap: 10px;
    }
    .menu_item {
        padding: 0px;
        text-align: center;
        margin-bottom: 0px;
        background-color: transparent;
        font-size: 14px;
    }
    .active {
        background-color: transparent;
        color: var(--bg-color);
    }
}
</style>