<template>
    <div class="blog_page">
        <div class="head">
            <div class="title">Blog</div>
            <div class="btn" @click="show_add_blog = true">Add Blog</div>
        </div>

        <div class="blog_grid">
            <blog_vue v-for="blog in blogs" :key="blog.link" :blog="blog"/>
        </div>

        <add_blog_vue v-if="show_add_blog" @close="show_add_blog = false"/>
    </div>
</template>

<script setup>
import add_blog_vue from '@/components/admin/blog/add.vue';
import blog_vue from '@/components/admin/blog/blog.vue';

import { ref, inject, onMounted } from 'vue';

const show_add_blog = ref(false)


const axios = inject('axios_2')

const blogs = ref([])
const loading = ref(true)

onMounted(() => {
    axios.post('/blog_posts')
    .then(data => {
        let r = data.data
        blogs.value = r.blog
        loading.value = false
    })
})


</script>

<style scoped>
.blog_page {
    padding: 20px;
}
.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title {
    
    font-size: 18px;
    font-weight: 600;
}
.btn {
    background-color: var(--bg-color);
    color: white;
    padding: 8px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}
.blog_grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
}
</style>