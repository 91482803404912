<template>
    <div class="popup">
        <div class="overlay"></div>
        <div class="box">
            <div class="head">
                <div class="title">Add Blog Post</div>
                <div class="close" @click="emit('close')">
                    <close_vue :size="'20px'" :style="{'height': '20px'}"/>
                </div>
            </div>
            <div class="main">
                <div class="input">
                    <div class="label">Title</div>
                    <input type="text" placeholder="Blog Title" v-model="title">
                </div>
                <div class="input">
                    <div class="label">Image</div>

                    <div class="cover_image" v-if="is_image">
                        <div class="remove_image" @click="remove_image()">Remove Image</div>
                        <img :src="image"/>
                    </div>
                    <div class="upload" v-else @click="upload()">Upload image</div>

                </div>
                <div class="input">
                    <div class="label">Content</div>
                    <QuillEditor theme="snow" toolbar="full" ref="editor"/>
                </div>
                
                <loader_vue v-if="loading"/>
                <div class="btn" v-else @click="post()">Post</div>

            </div>
        </div>

        <input type="file" accept="image/*" class="hidden" ref="uploader" @change="handleupload($event)"/>
    </div>
</template>

<script setup>
import * as imageConversion from 'image-conversion';
import UrlSafeString from 'url-safe-string'


import close_vue from '@/icons/close.vue';
import loader_vue from '@/components/loader.vue';

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { defineEmits,  inject,  ref } from 'vue';

// const toast = inject('')

const axios = inject('axios_2')
const emit = defineEmits(['close'])

import { useRouter } from 'vue-router';
const router = useRouter()


const uploader = ref()
const upload = () => {
    uploader.value.click()
}

const is_image = ref(false)
const image = ref('')
const compressed_image = ref(null)

const handleupload = (event) => {
    let mediaType = event.target.files[0].type
    if (mediaType.includes('image')) {
        const picture = event.target.files[0]
        
        compress(picture)
        const reader = new FileReader()
        reader.readAsDataURL(picture)
        reader.onload = event => {
            image.value = event.target.result
        }
    }
}
const remove_image = () => {
    is_image.value = false
    image.value = ''
    compressed_image.value = null
}

const compress = (file) => {
    let name = file.name
    
    imageConversion.compressAccurately(file, 150).then(res=>{
        is_image.value = true
        
        compressed_image.value = new File([res], name)
    })
}

const title = ref('')
const editor = ref(null)

const loading = ref(false)

const post = () => {

    let tagGenerator  = new UrlSafeString();
    let link = tagGenerator.generate(title.value);
    

    let text = editor.value.getText()
    let html = editor.value.getHTML()

    if (text.trim() == '' || title.value == '') {
        alert('Enter blog title and content')
        return
    }

    loading.value = true

    
    let form = new FormData()
    form.append('title', title.value)
    form.append('html', html)
    form.append('link', link)
    form.append('is_image', is_image.value ? '1' : '0' )
    if (is_image.value) {
        form.append('image', compressed_image.value)
    }

    axios.post('/admin/blog/new', form)
    .then(data => {
        let r = data.data
        if (r.success) {
            router.go()
        }
    })
}

</script>

<style scoped>
.popup {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    padding: 20px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;
}
.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.8);
}

.box {
    position: relative;
    background-color: white;
    max-width: 900px;
    width: 100%;
    height: 80vh;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
}
.head {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #dddddd;
}
.title {
    font-weight: 600;
}
.close {
    cursor: pointer
}


.main {
    flex: auto;
    
    /* display: flex; */
    /* flex-direction: column; */
    /* gap: 20px; */

    overflow: auto;
    padding: 20px;
}
.label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}

.cover_image {
    position: relative
}
.cover_image img {
    width: 100%;
}
.remove_image {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: var(--bg-color);
    color: white;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
}

.input {
    margin-bottom: 20px;
}
input {
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    border-radius: 10px;
}
.upload {
    background-color: var(--bg-color);
    color: white;
    border-radius: 5px;
    width: fit-content;
    font-size: 12px;
    padding: 10px 30px;
    cursor: pointer;
}

.btn {
    background-color: var(--bg-color);
    color: white;
    border-radius: 5px;
    text-align: center;

    font-size: 14px;
    padding: 13px 30px;
    cursor: pointer;
}

    
.hidden {
        display: none;
    }
</style>