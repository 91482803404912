<template>
    <div class="blog_page">
        <header_vue/>

        

        <div class="loading" v-if="loading">
            <loading_vue/>
        </div>
        <div class="banner_wrapper">
            <div class="banner">
                <div class="title">{{ blog.title }}</div>
                <div class="sub">
                    <date_icon :size="'20px'" :style="{'height': '20px'}"/>
                    <div>{{ date }}</div>
                    <div>By Admin</div>
                </div>
            </div>
        </div>
        <div class="main" v-if="!loading">
            <!-- <div class="title">{{ blog.title }}</div> -->
            <!-- <div class="date">
                <date_icon :size="'20px'" :style="{'height': '20px'}"/>
                <div>{{ date }}</div>
            </div> -->
            <div class="cover_image" v-if="blog.image">
                <img :src="'https://php.commodities-tracker.com/images/blog/' + blog.image"/>
            </div>
            <div class="content" v-html="blog.content"></div>
        </div>

        <footer_vue/>
    </div>
</template>

<script setup>
import moment from 'moment';

import header_vue from '@/components/header.vue';
import loading_vue from '@/components/loader.vue';
import date_icon from '@/icons/date.vue';

import footer_vue from '@/components/footer.vue';
import { onMounted, ref, inject } from 'vue';
import { useRoute } from 'vue-router';

const axios = inject('axios_2')

const route = useRoute()

const loading = ref(true)
const blog = ref({})
const date = ref('')

const link_url = ref('')

onMounted(() => {
    window.scrollTo(0, 0)

    setTimeout(() => {
        
        let link = route.params.link
        link_url.value = link
        let b = localStorage.getItem('blog')

        if (b) {
            let blog_content = JSON.parse(b)
            if (link.toLowerCase() == blog_content.link.toLowerCase()) {
                blog.value = blog_content
                
                date.value = moment(blog_content.created).format('YYYY-MM-DD')
                loading.value = false
            } else {
                fetch_blog()
            }
        } else {
            fetch_blog()
        }

        
    }, 1000);
})

const fetch_blog = () => {
    let form = new FormData()
    form.append('link', link_url.value)

    axios.post('/blog_post', form)
    .then(data => {
        let r = data.data
        if (r.success) {
            blog.value = r.blog
            
            date.value = moment(blog.value.created).format('YYYY-MM-DD')

            loading.value = false
        }
    })
}

</script>

<style scoped>
.loading {
    min-height: 100vh;
    padding: 50px 20px;
}


.banner_wrapper {

    background-color: #290645;
    background: url('../assets/about_banner.svg');
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    padding: 100px 20px;
}
.banner {

    color: white;

    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
}
.title {
    font-weight: 800;
    font-size: 35px;
    text-align: center;
}
.sub {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    opacity: .6;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
    fill: white;
}



.main {
    padding: 50px 20px;
    box-sizing: border-box;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
}

/* .title {
    font-size: 35px;
    font-weight: 800;
} */

.cover_image {
    
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    overflow: hidden;
    border-radius: 20px;
}
.cover_image img{
    width: 100%;
}

.date {
    display: flex;
    align-items: center;
    gap: 10px;
    opacity: .6;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
}


@media screen and (max-width: 600px) {
    .banner_wrapper {
        padding: 50px 20px;
    }
    .title {
        font-size: 30px;
    }
}

</style>