import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import about from '@/views/about.vue'
import blog from '@/views/blog.vue'
import blog_post from '@/views/blog_post.vue'

import admin_login from '../views/admin/login.vue'
import admin from '../views/admin/admin.vue'
import dashboard from '../views/admin/dashboard.vue'
import commodities from '../views/admin/commodities.vue'
import admin_blog from '@/views/admin/blog.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/about',
        name: 'about',
        component: about
    },
    {
        path: '/blog',
        name: 'blog',
        component: blog
    },
    {
        path: '/blog/:link',
        name: 'blog_post',
        component: blog_post
    },
    {
        path: '/admin/login',
        name: 'admin_login',
        component: admin_login
    },
    {
        path: '/admin',
        name: 'admin',
        component: admin,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: dashboard
            },
            {
                path: 'commodities',
                name: 'commodities',
                component: commodities
            },
            {
                path: 'blog',
                name: 'admin_blog',
                component: admin_blog
            }
        ]
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
