<template>
    <div :style="{'--bg-color': c.main, '--transparent-color': c.trans}">
        <router-view/>
    </div>
</template>


<script setup>
import { ref } from "vue"

document.title = 'Commodities Tracker'

import { store } from './store/store.js'

import {state, socket} from './socket'
if (!state.connected) {
    socket.connect()
}

const c = ref({
    'main': 'rgb(48, 79, 254)',
    'trans': 'rgba(48, 79, 254, 0.2)'
})

    

socket.on('new_data', (data) => {
    
    for (let x of data.response) {
        store.commodities[x.symbol] = x
    }
})
</script>

<style>

@import 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';

@import url('https://fonts.googleapis.com/css2?family=Potta+One&display=swap');

#app {
  font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    padding: 0px;
    margin: 0px;

}

a {
    color: inherit;
    text-decoration: none;
}


.content a {
    color: #2b70fa !important;
    font-weight: 600;
}

</style>
