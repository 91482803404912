<template>
    <div class="header">
        <div class="logo">
            <router-link to="/">
                Commodities Tracker
            </router-link>
        </div>
        <div class="menu">
            <router-link to="/">
                <div class="item">Home</div>
            </router-link>
            <router-link to="/about">
                <div class="item">About</div>
            </router-link>
            <router-link to="/blog">
                <div class="item">Blog</div>
            </router-link>
        </div>
        
        <a href="https://weeklycommoditiesreport.substack.com" target="blank">
            <div class="btn">Subscribe</div>
        </a>

        <div class="menu_icon" @click="show_menu = true">
            <menu_icon :size="'20px'" :style="{'height': '20px'}"/>
        </div>

        <div class="popup" v-if="show_menu">
            <div class="overlay" @click="show_menu = false"></div>
            <div class="box">
                <div class="close" @click="show_menu = false">
                    <close_icon :size="'20px'" :style="{'height': '20px'}"/>
                </div>
                <div class="link">
                    <router-link to="/">
                        <div class="item">Home</div>
                    </router-link>
                </div>
                <div class="link">
                    <router-link to="/about">
                        <div class="item">About</div>
                    </router-link>
                </div>
                <div class="link">
                    <router-link to="/blog">
                        <div class="item">Blog</div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import close_icon from '@/icons/close.vue';
import menu_icon from '@/icons/menu.vue';
import { ref } from 'vue';

const show_menu = ref(false)

</script>

<style scoped>
.header {
    padding: 20px;
    background-color: #290645;
    padding: 20px;
    color: white;

    display: flex;
    align-items: center;
    gap: 50px;
    
    position: sticky;
    top: 0px;
    z-index: 3;
}
.logo {
    font-family: "Potta One", system-ui;
    font-size: 20px;
    /* font-weight: 800; */
}
.menu {
    display: flex;
    gap: 20px;
    font-size: 14px;
}
.active {
    color: #2b70fa;
}


a {
    margin-left: auto;
}
.btn {
    background-color: #2b70fa;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}


.menu_icon {
    background-color: white;
    fill: #290645;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    display: none;
}

@media screen and (max-width: 750px) {
    .menu {
        display: none;
    }
    .header {
        gap: 20px;
    }
    .menu_icon {
        display: block;
    }
    .logo {
        font-size: 16px;
    }
}


.popup {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    /* padding: 20px; */
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.9);
}

.box {
    position: relative;
    background-color: white;
    width: 250px;
    height: 100vh;
    /* border-radius: 20px; */

    display: flex;
    flex-direction: column;
    
    color: #290645;
}

.close {
    padding: 20px;
    text-align: right;
    cursor: pointer;
}
.link {
    padding: 15px 20px;
    border-bottom: 1px solid #dddddd;
    font-size: 14px;
}

</style>