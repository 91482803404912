<template>
    <div class="blog_wrapper">
        <div class="blog">
            <div class="title">Blog</div>
            <div class="sub">Home / Blog</div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>

.blog_wrapper {

    background-color: #290645;
    background: url('../../assets/about_banner.svg');
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 20px;
}
.blog {

    color: white;

    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
}
.title {
    font-weight: 800;
    font-size: 35px;
}
.sub {
    opacity: .6;
    font-size: 14px;
}



@media screen and (max-width: 600px) {
    .blog_wrapper {
        padding: 50px 20px;
    }
}


</style>