<template>
    <div class="dashboard">
        <div class="title">Page Views</div>

        <div class="table">
            <div class="table_head">
                <div class="table_head_item">Date</div>
                <div class="table_head_item">Page Views</div>
                <div class="table_head_item">Unique Views</div>
            </div>

            <div class="load" v-if="loading">
                <loader/>
            </div>
            <div v-else>
                <div v-for="(views, i) in page_views" :key="i" class="table_data">
                    <div class="table_data_item">{{ views.date }}</div>
                    <div class="table_data_item">{{ views.page_views }}</div>
                    <div class="table_data_item">{{ views.unique_views }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import loader from "@/components/loader.vue";
import { inject, ref } from "vue"


const page_views = ref([])
const loading = ref(true)


const axios_2 = inject('axios_2')
const init = () => {
    axios_2.post('/admin/page_views')
    .then(data => {
        let r = data.data
        if (r.success) {
            page_views.value = r.page_views

            loading.value = false
        }
    })
}
init()

</script>

<style scoped>
.dashboard {
    padding: 20px;
}
.title {
    
    font-size: 18px;
    font-weight: 600;
}
.table {
    margin-top: 20px;
}
.table_head {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    border-bottom: 1px solid #333;
    font-size: 14px;
    font-weight: 600;
}
.table_head_item {
    padding: 20px;
}

.load {
    margin-top: 50px;
}

.table_data {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    font-size: 14px;
}
.table_data:nth-child(even) {
    background-color: #f8f8f8;
    border-radius: 5px;
}
.table_data_item {
    padding: 15px 20px;
}

@media screen and (max-width: 700px) {
    .table {
        margin: 0px -20px;
    }
}
</style>